<template>
  <div v-if="themeName == 'joe'">
    <div class="joe_container" >
      <div class="joe_main joe_post">
        <div class="joe_detail"  data-cid="658">
          <div class="joe_detail__category">
<!--            todo 标签 -->
<!--            <a href="https://www.nmssb.cn/category/jiaochene.html" class="item item-0" title="教程">教程</a>-->
<!--            <a href="https://www.nmssb.cn/category/fenxiane.html" class="item item-1" title="分享">分享</a>-->
          </div>
          <h1 class="joe_detail__title">{{title}}</h1>
          <div class="joe_detail__count">
            <div class="joe_detail__count-information">
              <img width="35" height="35" class="avatar lazyloaded" :src="$store.state.webInfo.avatar" :data-src="$store.state.webInfo.avatar" :alt="$store.state.webInfo.webName">
              <div class="meta">
                <div class="author">
                  <a class="link" :href="$constant.webURL" :title="$store.state.webInfo.webName">{{$store.state.webInfo.webName}}</a>
                </div>
                <div class="item">
                  <span class="text"><i class="v-bzhu-icon v-bzhu-icon-rili"></i>{{createTime}}</span>
                  <span class="line">/<i class="v-bzhu-icon v-bzhu-icon-pinglun"></i></span>
                  <span class="text">0 评论</span>
                  <span class="line">/<i class="v-bzhu-icon v-bzhu-icon-yuedu"></i></span>
                  <span class="text" id="Joe_Article_Views">{{viewCount}} 阅读</span>
                  <span class="line">/<i class="v-bzhu-icon v-bzhu-icon-shoulu"></i></span>
<!--                  <span class="text" id="Joe_Baidu_Record" style="color: rgb(103, 194, 58);">已收录</span>-->
                </div>
              </div>
            </div>
            <time class="joe_detail__count-created" :datetime="updateTime">{{updateTime}}</time>
          </div>                      <div class="joe_detail__overdue">
          <div class="joe_detail__overdue-wrapper">
            <div class="title">
              <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <path d="M0 512c0 282.778 229.222 512 512 512s512-229.222 512-512S794.778 0 512 0 0 229.222 0 512z" fill="#FF8C00" fill-opacity=".51"></path>
                <path d="M462.473 756.326a45.039 45.039 0 0 0 41.762 28.74 45.039 45.039 0 0 0 41.779-28.74h-83.541zm119.09 0c-7.73 35.909-39.372 62.874-77.311 62.874-37.957 0-69.598-26.965-77.33-62.874H292.404a51.2 51.2 0 0 1-42.564-79.65l23.723-35.498V484.88a234.394 234.394 0 0 1 167.492-224.614c3.635-31.95 30.498-56.815 63.18-56.815 31.984 0 58.386 23.808 62.925 54.733A234.394 234.394 0 0 1 742.093 484.88v155.512l24.15 36.454a51.2 51.2 0 0 1-42.668 79.48H581.564zm-47.957-485.922c.069-.904.12-1.809.12-2.73 0-16.657-13.26-30.089-29.491-30.089-16.214 0-29.474 13.432-29.474 30.089 0 1.245.085 2.491.221 3.703l1.81 15.155-14.849 3.499a200.226 200.226 0 0 0-154.265 194.85v166.656l-29.457 44.1a17.067 17.067 0 0 0 14.182 26.556h431.155a17.067 17.067 0 0 0 14.234-26.487l-29.815-45.04V484.882A200.21 200.21 0 0 0 547.26 288.614l-14.985-2.986 1.331-15.224z" fill="#FFF"></path>
                <path d="M612.864 322.697c0 30.378 24.303 55.022 54.272 55.022 30.003 0 54.323-24.644 54.323-55.022 0-30.38-24.32-55.023-54.306-55.023s-54.306 24.644-54.306 55.023z" fill="#FA5252"></path>
              </svg>
              <span class="text">温馨提示：</span>
            </div>
            <div class="content">
              本文最后更新于{{updateTime}}，已超过{{Day}}天没有更新，若内容或图片失效，请留言反馈。
            </div>
          </div>
        </div>
          <!--礼貌四连-->
          <center><img src="https://pan.nmssb.cn/view.php/c77fb0713f60a77d7fed3f361d0c9656.png" alt="scimg" height="auto" width="100%"></center>
          <article class="joe_detail__article">
            <div v-html="articleContentHtml" class="entry-content" ref="ff"></div>
            <el-image v-if="imgPreviewUrl" ref="previewImg" style="display: none" :src="imgPreviewUrl"
                      :preview-src-list="srcList">
            </el-image>
<!--            内容-->
          </article>
          <div class="joe_detail__agree">
            <div class="agree" @click="like">
              <div class="icon">
                <svg class="icon-1" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                <path d="M736 128c-65.952 0-128.576 25.024-176.384 70.464-4.576 4.32-28.672 28.736-47.328 47.68L464.96 199.04C417.12 153.216 354.272 128 288 128 146.848 128 32 242.848 32 384c0 82.432 41.184 144.288 76.48 182.496l316.896 320.128C450.464 911.68 478.304 928 512 928s61.568-16.32 86.752-41.504l316.736-320 2.208-2.464C955.904 516.384 992 471.392 992 384c0-141.152-114.848-256-256-256z" fill="#fff"></path>
                </svg>
                <svg class="icon-2 active" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                  <path d="M512 928c-28.928 0-57.92-12.672-86.624-41.376L106.272 564C68.064 516.352 32 471.328 32 384c0-141.152 114.848-256 256-256 53.088 0 104 16.096 147.296 46.592 14.432 10.176 17.92 30.144 7.712 44.608-10.176 14.432-30.08 17.92-44.608 7.712C366.016 204.064 327.808 192 288 192c-105.888 0-192 86.112-192 192 0 61.408 20.288 90.112 59.168 138.688l315.584 318.816C486.72 857.472 499.616 863.808 512 864c12.704.192 24.928-6.176 41.376-22.624l316.672-319.904C896.064 493.28 928 445.696 928 384c0-105.888-86.112-192-192-192-48.064 0-94.08 17.856-129.536 50.272l-134.08 134.112c-12.512 12.512-32.736 12.512-45.248 0s-12.512-32.736 0-45.248L562.24 196c48.32-44.192 109.664-68 173.76-68 141.152 0 256 114.848 256 256 0 82.368-41.152 144.288-75.68 181.696l-317.568 320.8C569.952 915.328 540.96 928 512 928z" fill="#fff"></path>
                </svg>
              </div>
              <span class="text">5</span>
            </div>
          </div>
          <div class="joe_detail__operate">
<!--          <div class="joe_detail__operate-tags">-->
<!--            //todo 标签暂时不采用-->
<!--            <a href="">js代码</a>-->
<!--          </div>-->
<!--          <div class="joe_detail__operate-share">-->
<!--            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="26" height="26">-->
<!--              <path d="M13.4 512.8c0 276 224 500 500 500s500-224 500-500-224-500-500-500c-276.6 0-500 224-500 500z" fill="#8F7DF6"></path>-->
<!--              <path d="M513.4 233.8c13 0 23.8 10.6 23.8 23.8 0 13-10.6 23.8-23.8 23.8-128 0-231.6 103.8-231.6 231.6s103.8 231.6 231.6 231.6S745 640.8 745 513c-.4-13 9.8-24 22.8-24.6 13-.4 24 9.8 24.6 22.8v1.6c0 154-125 279-279 279s-279-125-279-279 125-279 279-279zM657 352.4c-8.6-9.6-7.8-24.4 1.8-33 9-8 22.6-8 31.4.2l40.8 40.8c12.4 12.2 12.4 32.2 0 44.6l-40.8 41c-9.4 8.8-24 8.6-33-.8-8.6-9-8.6-23.2 0-32.2l7-7h-30c-81.8 0-109.6 34.8-109.6 140.2 0 12.8-10.4 23.2-23.2 23.2-12.8 0-23.2-10.4-23.2-23.2 0-130.2 47.6-186.8 156.2-186.8h30l-7.4-7z" fill="#FFF"></path>-->
<!--            </svg>-->
<!--            <div class="reach">-->
<!--              <a href="https://connect.qq.com/widget/shareqq/index.html?url=https://www.nmssb.cn/658.html&amp;title=[网站记录]网站底部建站时间代码&amp;pics=https://www.nmssb.cn/usr/uploads/2023/07/1808521733.png" target="_blank" rel="noopener noreferrer" title="分享到QQ">-->
<!--                <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30">-->
<!--                  <path d="M512 73.28A438.72 438.72 0 1 0 950.72 512 438.72 438.72 0 0 0 512 73.28zM759.84 646.4c-9.6 8.16-20.8-6.08-29.76-20.32s-14.88-26.72-16-21.76a158.4 158.4 0 0 1-37.44 70.72c-1.28 1.6 28.8 12.48 37.44 35.68s24 57.6-80 68.8a145.76 145.76 0 0 1-80-16c-16.96-8.32-27.52-16-29.6-16a73.6 73.6 0 0 1-13.28 0 108 108 0 0 1-14.4 0c-1.76 0-22.24 32-113.12 32-70.4 0-88.64-44.32-74.4-68.8s37.76-32 34.4-35.36a192 192 0 0 1-34.4-57.6 98.56 98.56 0 0 1-4.16-13.44c-1.28-4.64-6.56 8.64-13.92 21.76s-14.4 22.72-22.88 22.72a11.52 11.52 0 0 1-6.56-2.4c-20.96-16-19.2-55.2-5.44-93.12s48-75.04 48-83.2c1.28-30.24-3.04-35.2 0-43.2 6.56-17.76 14.72-10.88 14.72-20.16 0-116.32 86.4-210.56 192.96-210.56s192.96 94.24 192.96 210.56c0 4.48 11.68 0 17.12 20.16a196.96 196.96 0 0 1 0 43.2c0 11.04 29.44 24.48 44.8 83.2S768 640 759.84 646.4z" fill="#68A5E1"></path>-->
<!--                </svg>-->
<!--              </a>-->
<!--              <a href="https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https://www.nmssb.cn/658.html&amp;sharesource=qzone&amp;title=[网站记录]网站底部建站时间代码&amp;pics=https://www.nmssb.cn/usr/uploads/2023/07/1808521733.png" target="_blank" rel="noopener noreferrer" title="分享到QQ空间">-->
<!--                <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30">-->
<!--                  <path d="M512 73.28A438.72 438.72 0 1 0 950.72 512 438.72 438.72 0 0 0 512 73.28zM829.92 432c5.6 16-150.24 146.4-150.24 146.4s2.08 12.64 4.16 22.08c0 0-72.64 2.24-132.32 0-32-1.28-69.12-7.04-69.12-7.04L656 470.24a1005.44 1005.44 0 0 0-125.76-13.6A908 908 0 0 0 380 463.36c-6.4 1.76 44.8 1.6 103.04 6.88 40.8 3.68 94.56 13.44 94.56 13.44l-172.8 128s73.92 4.48 140.32 4.16c74.72 0 142.24-9.92 142.72-8 12.96 56.16 36.96 167.52 28 176-12.16 12.32-185.6-97.6-185.6-97.6S368 785.6 345.92 785.6a3.68 3.68 0 0 1-2.08 0c-10.72-8.8 35.52-206.72 35.52-206.72S222.72 448 229.12 432s208-30.24 208-30.24 74.88-188 92.48-188 92.8 188 92.8 188S824.32 416 829.92 432z" fill="#F5BE3F"></path>-->
<!--                </svg>-->
<!--              </a>-->
<!--              <a href="http://service.weibo.com/share/share.php?sharesource=weibo&amp;title=分享：[网站记录]网站底部建站时间代码，原文链接：https://www.nmssb.cn/658.html&amp;pic=https://www.nmssb.cn/usr/uploads/2023/07/1808521733.png" target="_blank" rel="noopener noreferrer" title="分享到新浪微博">-->
<!--                <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30">-->
<!--                  <path d="M480.8 457.76a271.04 271.04 0 0 0-37.28 2.72c-96 13.44-166.72 75.04-157.92 137.44s93.6 101.92 189.6 88.48 166.72-75.04 157.92-137.44c-7.68-54.08-73.12-91.04-152.32-91.2zm-23.36 211.52a122.08 122.08 0 0 1-24 2.4c-48 0-88-27.52-96-68.32-9.28-48 29.44-95.2 86.56-106.24s110.88 18.4 120 66.08-29.44 95.04-86.56 106.08z" fill="#F56467"></path>-->
<!--                  <path d="M512 73.28A438.72 438.72 0 1 0 950.72 512 438.72 438.72 0 0 0 512 73.28zm-43.84 666.88c-150.24 0-272-78.56-272-176S378.56 314.72 448 314.72c29.28 0 86.56 21.76 46.4 90.88a246.24 246.24 0 0 0 34.08-10.08c32-9.12 76.96-18.24 107.68 0 51.04 29.6 0 77.12 0 82.4s102.4 5.28 102.4 87.2c.32 96.48-120.16 175.04-270.4 175.04zm213.76-358.88a56 56 0 0 0-47.2-16 16.96 16.96 0 0 1-17.28-14.4 12.16 12.16 0 0 0 0 2.4v-4.8a12.16 12.16 0 0 0 0 2.4 20.48 20.48 0 0 1 17.28-17.28 77.28 77.28 0 0 1 68.32 18.56c32 28.48 18.72 75.68 18.72 75.68a21.28 21.28 0 0 1-20.48 17.28h-1.76a12.48 12.48 0 0 1-12.8-16.8 49.44 49.44 0 0 0-4.8-47.04zm120.16 60.64A29.6 29.6 0 0 1 776 467.84a22.08 22.08 0 0 1-19.68-25.92A139.2 139.2 0 0 0 736 336c-34.88-50.08-109.92-41.28-109.92-41.28A26.24 26.24 0 0 1 599.84 272v2.88-5.6V272a26.56 26.56 0 0 1 26.24-23.52 188.32 188.32 0 0 1 136.16 47.04c58.08 55.04 39.84 146.4 39.84 146.4z" fill="#F56467"></path>-->
<!--                  <path d="M459.36 547.04a17.6 17.6 0 1 0 17.6 17.6 17.6 17.6 0 0 0-17.6-17.6zm-44.32 23.2a43.52 43.52 0 0 0-18.4 4.32A32 32 0 0 0 376 613.12a32 32 0 0 0 42.88 9.12 32 32 0 0 0 20.64-38.72 25.76 25.76 0 0 0-24.48-13.28z" fill="#F56467"></path>-->
<!--                </svg>-->
<!--              </a>-->
<!--              &lt;!&ndash;-->
<!--                  @杜恒：如果你有海报插件，将下面的注释解开，通过点击下方的图标调用生成海报-->
<!--                  <a href="javascript: void(0);" title="生成海报分享">-->
<!--                      <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30">-->
<!--                          <path d="M652.48 283.84h52.48q17.6 0 17.6 17.6v52.48q0 17.6-17.6 17.6h-52.48q-17.6 0-17.6-17.6v-52.48q0-17.6 17.6-17.6zM336.48 248.8h17.6v105.28h-17.6zm35.2 0h17.6v105.28h-17.6z" fill="#D7C5B0" />-->
<!--                          <path d="M512 73.28A438.72 438.72 0 1 0 950.72 512 438.72 438.72 0 0 0 512 73.28zm263.2 596.64A105.12 105.12 0 0 1 669.92 775.2H354.08A105.28 105.28 0 0 1 248.8 669.92V441.76h121.76a157.92 157.92 0 1 0 282.88 0H775.2zM389.12 512A122.88 122.88 0 1 1 512 634.88 122.88 122.88 0 0 1 389.12 512zM775.2 406.72H629.76a158.08 158.08 0 0 0-235.52 0H248.8v-87.68a70.4 70.4 0 0 1 52.64-68v103.04h17.6V248.8H308h5.28a32 32 0 0 1 5.76 0h385.92a70.24 70.24 0 0 1 70.24 70.24z" fill="#D7C5B0" />-->
<!--                      </svg>-->
<!--                  </a>-->
<!--              &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
        </div>
          <!--免费宣传位置-->
          <span>
            <a href="https://www.shdf.gov.cn/shdf/channels/740.html?spm=a21n57.1.1997523009.53.749b523c38IVlp" target="_blank" rel="noopener noreferrer">
              <img style="border-radius:5px" src="https://pan.nmssb.cn/view.php/f36284f7c031633b8861b335d9ab1aa5.jpg" alt="adLINK" height="120PX" width="100%">
            </a>
          </span>
          <!--关注公众号图片地址
          // todo -->
<!--          <span>-->
<!--            <img :src="" width="100%" height="auto">-->
<!--          </span>-->
          <div class="joe_detail__copyright">
            <div class="content">
              <div class="item">
                <svg class="icon" width="20" height="20" viewBox="0 0 1024 1024">
                  <path d="M614.72 554.538c-49.086-6.399-100.27-2.1-149.256-2.1-119.465 0-209.04 95.972-206.84 215.437 0 17.095 8.498 31.99 23.493 40.488 14.896 10.697 34.09 14.896 53.285 17.095 61.882 6.398 123.664 6.398 198.342 6.398 40.488 0 93.872-2.1 142.858-4.298 27.692 0 53.284-4.3 78.877-14.896 19.194-8.498 29.89-19.194 31.99-40.488 8.498-104.57-72.478-204.84-172.75-217.636zM680.8 375.39c0-87.474-74.678-162.053-164.251-162.053-89.574 0-162.053 74.679-162.053 162.053-2.1 87.474 74.678 164.252 162.053 164.252 89.673 0 164.252-74.678 164.252-164.252z" fill="#FFF"></path>
                  <path d="M512.35 0C228.733 0 .5 228.233.5 511.85s228.233 511.85 511.85 511.85 511.85-228.233 511.85-511.85S795.967 0 512.35 0zm275.12 772.074c-2.1 21.294-12.797 31.99-31.991 40.488-25.593 10.697-51.185 14.896-78.877 14.896-49.086 2.099-102.37 4.298-142.858 4.298-74.678 0-136.46 0-198.342-6.398-19.195-2.1-38.389-6.398-53.285-17.095-14.895-8.497-23.493-23.493-23.493-40.488-2.1-119.465 87.475-215.437 206.84-215.437 49.085 0 100.27-4.299 149.256 2.1 100.27 12.896 181.247 113.166 172.75 217.636zM354.495 375.39c0-87.474 72.479-162.053 162.053-162.053S680.8 288.016 680.8 375.39c0 89.574-74.679 164.252-164.252 164.252-87.375 0-164.152-76.778-162.053-164.252z" fill="#249FF8"></path>
                </svg>
                <span>版权属于:</span>
                <span class="text">{{$store.state.webInfo.webName}}</span>
              </div>
              <div class="item">
                <svg class="icon" width="20" height="20" viewBox="0 0 1024 1024">
                  <path d="M511.854 0A511.854 511.854 0 1 0 1024 511.854 511.854 511.854 0 0 0 511.854 0z" fill="#39B54A"></path>
                  <path d="M576.491 630.355L460.028 746.818a129.565 129.565 0 0 1-182.555 0l-2.038-2.038a128.983 128.983 0 0 1 0-182.264l81.233-81.233a179.644 179.644 0 0 0 13.102 70.46l-52.7 52.408a69.878 69.878 0 0 0 0 98.703l2.038 2.038a70.169 70.169 0 0 0 98.703 0l116.463-116.463a69.878 69.878 0 0 0 0-98.703l-2.039-2.038a69.587 69.587 0 0 0-13.975-10.772l42.509-42.51a128.11 128.11 0 0 1 13.102 11.356l2.038 2.038a129.274 129.274 0 0 1 0 182.264z" fill="#FFF"></path>
                  <path d="M746.236 460.902l-81.233 81.233a179.353 179.353 0 0 0-13.102-70.46l52.7-52.409a69.878 69.878 0 0 0 0-98.702l-2.039-2.038a69.878 69.878 0 0 0-98.702 0L487.397 434.989a69.878 69.878 0 0 0 0 98.702l2.038 2.038a68.422 68.422 0 0 0 13.976 10.773l-42.51 42.51a136.553 136.553 0 0 1-13.101-11.356l-2.038-2.038a128.983 128.983 0 0 1 0-182.265l116.463-116.462a129.565 129.565 0 0 1 182.555 0l2.038 2.038a128.983 128.983 0 0 1 0 182.264z" fill="#FFF"></path>
                </svg>
                <span>本文链接:</span>
                <span class="text">
                  <a class="link" :href="nowURL" rel="noopener noreferrer nofollow">{{ nowURL }}</a>
      </span>
              </div>
              <div class="item">
                <svg class="icon" width="20" height="20" viewBox="0 0 1024 1024">
                  <path d="M0 512a512 512 0 1 0 1024 0A512 512 0 1 0 0 512z" fill="#F3B243"></path>
                  <path d="M540.672 323.584a90.112 90.112 0 1 0 180.224 0 90.112 90.112 0 1 0-180.224 0zM540.672 688.128a90.112 90.112 0 1 0 180.224 0 90.112 90.112 0 1 0-180.224 0zM229.376 512a90.112 90.112 0 1 0 180.224 0 90.112 90.112 0 1 0-180.224 0z" fill="#FFF"></path>
                  <path d="M341.037 480.37l257.344-175.718 27.713 40.592-257.34 175.718z" fill="#FFF"></path>
                  <path d="M349.053 488.452L601.907 670.56l-28.725 39.887L320.307 528.34z" fill="#FFF"></path>
                </svg>
                <span>作品声明:</span>
                <span class="text">
        <a class="link" href="#" rel="noopener noreferrer nofollow">转载请注明文章来源。</a>
                </span>
              </div>
            </div>
          </div>
<!--          <div class="joe_detail__related">-->
<!--            <div class="joe_detail__related-title">相关推荐</div>-->
<!--            <div class="joe_detail__related-content">-->
<!--&lt;!&ndash;            <a class="item" href="https://www.nmssb.cn/667.html" title="[随笔]禁止网站被F12">&ndash;&gt;-->
<!--&lt;!&ndash;              <img class=" lazyloaded" src="https://pan.nmssb.cn/view.php/f254b0ef710542c4a9f9e7548942708d.png" data-src="https://pan.nmssb.cn/view.php/f254b0ef710542c4a9f9e7548942708d.png" alt="[随笔]禁止网站被F12">&ndash;&gt;-->
<!--&lt;!&ndash;              <h6>[随笔]禁止网站被F12</h6>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
        </div>
<!--        // todo 推荐文章 and 上下一页 -->
<!--        <ul class="joe_post__pagination">-->
<!--          <li class="joe_post__pagination-item prev">-->
<!--            <a href="" title="[WAF防火墙]宝塔配合雷池WAF进行网站防护">上一篇</a>-->
<!--          </li>-->
<!--          <li class="joe_post__pagination-item next">-->
<!--            <a href="" title="[随记]当你的网站不想要超链接跳转的时候怎么办呢？">下一篇</a>-->
<!--          </li>-->
<!--        </ul>-->

        <div class="joe_comment">
<!--          <h3 class="joe_comment__title">评论 (0)</h3>-->
<!--          // todo 评论 -->
          <div v-if="article.commentStatus === true">
            <comment :type="'article'" :source="article.id" :userId="article.userId"></comment>
          </div>
        </div>
      </div>
      <ce-bian-lan ref="CeBianLan"></ce-bian-lan>
    </div>
  </div>
  <div v-else-if="!$common.isEmpty(article) && themeName !='joe'">
    <!-- 封面 -->
    <div class="article-head my-animation-slide-top">
      <!-- 背景图片 -->
      <el-image class="article-image my-el-image"
                v-once
                lazy
                :src="!$common.isEmpty(article.articleCover)?article.articleCover:$constant.random_image+new Date()+Math.floor(Math.random()*10)"
                fit="cover">
        <div slot="error" class="image-slot">
          <div class="article-image"></div>
        </div>
      </el-image>
      <!-- 文章信息 -->
      <div class="article-info-container">
        <div class="article-title">{{ article.articleTitle }}</div>
        <div class="article-info">
          <svg viewBox="0 0 1024 1024" width="14" height="14" style="vertical-align: -2px;">
            <path
              d="M510.4 65.5l259.69999999 0 1e-8 266.89999999c0 147.50000001-116.2 266.89999999-259.7 266.90000001-143.4 0-259.7-119.5-259.7-266.90000001 0.1-147.5 116.3-266.9 259.7-266.89999999z"
              fill="#FF9FCF"></path>
            <path
              d="M698.4 525.2l-13 0c53-48.4 86.5-117.8 86.5-195.20000001 0-10.2-0.7-20.3-1.8-30.19999999C613.8 377.50000001 438.6 444.9 266 437.7c15 33.4 36.7 63.1 63.5 87.5l-5.3 0c-122.6 0-225.5 88.1-248.8 204.1C340 677.2 597.7 609.2 862.2 585.7c-44.3-37.6-101.5-60.5-163.8-60.5z"
              fill="#FF83BB"></path>
            <path
              d="M862.2 585.7C597.7 609.2 340 677.2 75.4 729.3c-3.2 16.1-5 32.6-5 49.6 0 99.8 81.7 181.5 181.5 181.5l518.6 0c99.8 0 181.5-81.7 181.5-181.5 0.1-77.2-35-146.5-89.8-193.2z"
              fill="#FF5390"></path>
            <path
              d="M770.1 299.8C755.1 168 643.3 65.5 507.4 65.5c-146.1 0-264.5 118.4-264.5 264.5 0 38.4 8.3 74.8 23.1 107.7 172.6 7.2 347.8-60.2 504.1-137.9z"
              fill="#FF9FCF"></path>
            <path
              d="M436.4 282.1c0 24.1-19.6 43.7-43.7 43.7S349 306.2 349 282.1s19.6-43.7 43.7-43.7c24.19999999 0 43.7 19.6 43.7 43.7z"
              fill="#FFFFFF"></path>
            <path d="M625 282.1m-43.7 0a43.7 43.7 0 1 0 87.4 0 43.7 43.7 0 1 0-87.4 0Z" fill="#FFFFFF"></path>
          </svg>
          <span>&nbsp;{{ article.username }}</span>
          <span>·</span>
          <svg viewBox="0 0 1024 1024" width="14" height="14" style="vertical-align: -2px;">
            <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#409EFF"></path>
            <path
              d="M654.222222 256c-17.066667 0-28.444444 11.377778-28.444444 28.444444v56.888889c0 17.066667 11.377778 28.444444 28.444444 28.444445s28.444444-11.377778 28.444445-28.444445v-56.888889c0-17.066667-11.377778-28.444444-28.444445-28.444444zM369.777778 256c-17.066667 0-28.444444 11.377778-28.444445 28.444444v56.888889c0 17.066667 11.377778 28.444444 28.444445 28.444445s28.444444-11.377778 28.444444-28.444445v-56.888889c0-17.066667-11.377778-28.444444-28.444444-28.444444z"
              fill="#FFFFFF"></path>
            <path
              d="M725.333333 312.888889H711.111111v28.444444c0 31.288889-25.6 56.888889-56.888889 56.888889s-56.888889-25.6-56.888889-56.888889v-28.444444h-170.666666v28.444444c0 31.288889-25.6 56.888889-56.888889 56.888889s-56.888889-25.6-56.888889-56.888889v-28.444444h-14.222222c-22.755556 0-42.666667 19.911111-42.666667 42.666667v341.333333c0 22.755556 19.911111 42.666667 42.666667 42.666667h426.666666c22.755556 0 42.666667-19.911111 42.666667-42.666667v-341.333333c0-22.755556-19.911111-42.666667-42.666667-42.666667zM426.666667 654.222222h-56.888889c-17.066667 0-28.444444-11.377778-28.444445-28.444444s11.377778-28.444444 28.444445-28.444445h56.888889c17.066667 0 28.444444 11.377778 28.444444 28.444445s-11.377778 28.444444-28.444444 28.444444z m227.555555 0h-56.888889c-17.066667 0-28.444444-11.377778-28.444444-28.444444s11.377778-28.444444 28.444444-28.444445h56.888889c17.066667 0 28.444444 11.377778 28.444445 28.444445s-11.377778 28.444444-28.444445 28.444444z m0-113.777778h-56.888889c-17.066667 0-28.444444-11.377778-28.444444-28.444444s11.377778-28.444444 28.444444-28.444444h56.888889c17.066667 0 28.444444 11.377778 28.444445 28.444444s-11.377778 28.444444-28.444445 28.444444z"
              fill="#FFFFFF"></path>
          </svg>
          <span>&nbsp;{{ article.createTime }}</span>
          <span>·</span>
          <svg viewBox="0 0 1024 1024" width="14" height="14" style="vertical-align: -2px;">
            <path d="M14.656 512a497.344 497.344 0 1 0 994.688 0 497.344 497.344 0 1 0-994.688 0z"
                  fill="#FF0000"></path>
            <path
              d="M374.976 872.64c-48.299-100.032-22.592-157.44 14.421-211.37 40.448-58.966 51.115-117.611 51.115-117.611s31.659 41.386 19.115 106.005c56.149-62.72 66.816-162.133 58.325-200.405 127.317 88.746 181.59 281.002 108.181 423.381C1016 652.501 723.093 323.2 672.277 285.867c16.939 37.333 20.054 100.032-14.101 130.474-58.027-219.84-201.664-265.002-201.664-265.002 16.96 113.536-61.781 237.397-137.344 330.24-2.816-45.163-5.632-76.544-29.483-119.808-5.333 82.176-68.373 149.269-85.29 231.445-22.912 111.637 17.237 193.173 170.581 279.424z"
              fill="#FFFFFF"></path>
          </svg>
          <span>&nbsp;{{ article.viewCount }}</span>
          <span>·</span>
          <svg viewBox="0 0 1024 1024" width="14" height="14" style="vertical-align: -2px;">
            <path
              d="M113.834667 291.84v449.194667a29.013333 29.013333 0 0 0 28.842666 29.013333h252.928v90.453333l160.597334-90.453333h252.928a29.013333 29.013333 0 0 0 29.013333-29.013333V291.84a29.013333 29.013333 0 0 0-29.013333-29.013333h-665.6a29.013333 29.013333 0 0 0-29.696 29.013333z"
              fill="#FFDEAD"></path>
            <path
              d="M809.130667 262.826667h-665.6a29.013333 29.013333 0 0 0-28.842667 29.013333v40.106667a29.013333 29.013333 0 0 1 28.842667-29.013334h665.6a29.013333 29.013333 0 0 1 29.013333 29.013334V291.84a29.013333 29.013333 0 0 0-29.013333-29.013333z"
              fill="#FFF3DB"></path>
            <path
              d="M556.202667 770.048h252.928a29.013333 29.013333 0 0 0 29.013333-29.013333V362.837333s-59.733333 392.533333-724.309333 314.709334v63.488a29.013333 29.013333 0 0 0 28.842666 29.013333h253.098667v90.453333z"
              fill="#F2C182"></path>
            <path
              d="M619.008 632.32l101.888-35.157333-131.754667-76.117334 29.866667 111.274667zM891.904 148.992a61.44 61.44 0 0 0-84.138667 22.528l-19.968 34.133333 106.666667 61.610667 19.968-34.133333a61.781333 61.781333 0 0 0-22.528-84.138667z"
              fill="#69BAF9"></path>
            <path d="M775.338667 198.775467l131.669333 76.032-186.026667 322.218666-131.6864-76.032z"
                  fill="#F7FBFF"></path>
            <path
              d="M775.168 198.826667l-5.290667 9.216 59.221334 34.133333a34.133333 34.133333 0 0 1 12.458666 46.592l-139.946666 242.346667a34.133333 34.133333 0 0 1-46.762667 12.629333l-59.050667-34.133333-6.656 11.434666 88.746667 51.2L720.896 597.333333l186.026667-322.56z"
              fill="#D8E3F0"></path>
            <path
              d="M616.448 622.592l2.56 9.728 101.888-35.157333-44.885333-25.941334-59.562667 51.370667zM891.904 148.992c-1.024 0-2.218667-0.853333-3.242667-1.536A61.610667 61.610667 0 0 1 887.466667 204.8l-19.968 34.133333-73.728-42.496-5.12 8.704 106.666666 61.610667 19.968-34.133333a61.781333 61.781333 0 0 0-23.381333-83.626667z"
              fill="#599ED4"></path>
            <path
              d="M265.898667 417.621333H494.933333a17.066667 17.066667 0 1 0 0-34.133333H265.898667a17.066667 17.066667 0 1 0 0 34.133333zM265.898667 533.504H494.933333a17.066667 17.066667 0 0 0 0-34.133333H265.898667a17.066667 17.066667 0 0 0 0 34.133333z"
              fill="#3D3D63"></path>
            <path
              d="M959.488 354.645333a99.84 99.84 0 0 0-23.722667-127.488 78.677333 78.677333 0 0 0-142.848-64.170666l-11.605333 20.138666a17.066667 17.066667 0 0 0-20.821333 7.168l-32.085334 55.466667H142.677333a46.250667 46.250667 0 0 0-45.909333 46.08v449.194667a46.08 46.08 0 0 0 45.909333 46.08h236.032v73.386666a17.066667 17.066667 0 0 0 8.362667 14.848 17.066667 17.066667 0 0 0 8.704 2.218667 17.066667 17.066667 0 0 0 8.362667-2.218667l156.672-88.234666h248.32a46.08 46.08 0 0 0 46.08-46.08V398.677333L921.6 283.306667a17.066667 17.066667 0 0 0-4.266667-21.504l1.877334-3.413334a65.365333 65.365333 0 0 1 10.410666 79.189334l-53.077333 91.989333a56.832 56.832 0 0 0 20.821333 77.653333 17.066667 17.066667 0 0 0 24.234667-6.314666 17.066667 17.066667 0 0 0-6.997333-23.04 23.04 23.04 0 0 1-8.362667-31.061334z m-138.410667 386.389334a11.946667 11.946667 0 0 1-11.946666 11.946666H556.202667a17.066667 17.066667 0 0 0-8.362667 2.218667l-134.997333 76.117333v-61.269333a17.066667 17.066667 0 0 0-17.066667-17.066667H142.677333a11.946667 11.946667 0 0 1-11.776-11.946666V291.84a11.946667 11.946667 0 0 1 11.776-11.946667h565.930667L574.464 512a17.066667 17.066667 0 0 0-1.706667 12.970667L597.333333 615.253333H265.898667a17.066667 17.066667 0 1 0 0 34.133334h352.938666a17.066667 17.066667 0 0 0 5.802667 0l102.4-35.328a17.066667 17.066667 0 0 0 9.216-7.509334l85.333333-147.968z m-204.8-184.661334l63.829334 36.864-49.322667 17.066667z m206.848-170.666666v1.365333l-108.373333 186.709333-102.4-59.050666L781.482667 221.866667l102.4 59.050666z m76.458667-161.28L887.466667 244.224l-76.970667-44.373333 11.264-19.797334a44.544 44.544 0 1 1 77.141333 44.544z"
              fill="#3D3D63"></path>
          </svg>
          <span>&nbsp;{{ article.commentCount }}</span>
          <span>·</span>
          <svg viewBox="0 0 1024 1024" width="14" height="14" style="vertical-align: -2px;">
            <path
              d="M510.671749 348.792894S340.102978 48.827055 134.243447 254.685563C-97.636714 486.565724 510.671749 913.435858 510.671749 913.435858s616.107079-419.070494 376.428301-658.749272c-194.095603-194.096626-376.428302 94.106308-376.428301 94.106308z"
              fill="#FF713C"></path>
            <path
              d="M510.666632 929.674705c-3.267417 0-6.534833-0.983397-9.326413-2.950192-16.924461-11.872399-414.71121-293.557896-435.220312-529.448394-5.170766-59.482743 13.879102-111.319341 56.643068-154.075121 51.043536-51.043536 104.911398-76.930113 160.095231-76.930114 112.524796 0 196.878996 106.48115 228.475622 153.195078 33.611515-45.214784 122.406864-148.20646 234.04343-148.20646 53.930283 0 105.46603 24.205285 153.210428 71.941496 45.063335 45.063335 64.954361 99.200326 59.133795 160.920016C935.306982 641.685641 536.758893 915.327952 519.80271 926.859589a16.205077 16.205077 0 0 1-9.136078 2.815116zM282.857183 198.75574c-46.25344 0-92.396363 22.682605-137.127124 67.413365-36.149315 36.157501-51.614541 78.120218-47.25321 128.291898 17.575284 202.089671 352.199481 455.119525 412.332023 499.049037 60.434417-42.86732 395.406538-289.147446 414.567947-492.458945 4.933359-52.344159-11.341303-96.465029-49.759288-134.88199-41.431621-41.423435-85.24243-62.424748-130.242319-62.424748-122.041544 0-220.005716 152.203494-220.989114 153.742547-3.045359 4.806469-8.53335 7.883551-14.101159 7.534603a16.257266 16.257266 0 0 1-13.736863-8.184403c-0.902556-1.587148-91.569532-158.081365-213.690893-158.081364z"
              fill="#885F44"></path>
          </svg>
          <span>&nbsp;{{ article.likeCount }}</span>
        </div>
      </div>

      <div class="article-info-news"
           @click="weiYanDialogVisible = true"
           v-if="!$common.isEmpty($store.state.currentUser) && $store.state.currentUser.id === article.userId">
        <svg width="30" height="30" viewBox="0 0 1024 1024">
          <path d="M0 0h1024v1024H0V0z" fill="#202425" opacity=".01"></path>
          <path
            d="M989.866667 512c0 263.918933-213.947733 477.866667-477.866667 477.866667S34.133333 775.918933 34.133333 512 248.081067 34.133333 512 34.133333s477.866667 213.947733 477.866667 477.866667z"
            fill="#FF7744"></path>
          <path
            d="M512 221.866667A51.2 51.2 0 0 1 563.2 273.066667v187.733333H750.933333a51.2 51.2 0 0 1 0 102.4h-187.733333V750.933333a51.2 51.2 0 0 1-102.4 0v-187.733333H273.066667a51.2 51.2 0 0 1 0-102.4h187.733333V273.066667A51.2 51.2 0 0 1 512 221.866667z"
            fill="#FFFFFF"></path>
        </svg>
      </div>
    </div>
    <!-- 文章 -->
    <div style="background: var(--background);">
      <div class="article-container my-animation-slide-bottom">
        <!-- 最新进展 -->
        <div v-if="!$common.isEmpty(treeHoleList)" class="process-wrap">
          <el-collapse accordion value="1">
            <el-collapse-item title="最新进展" name="1">
              <process :treeHoleList="treeHoleList" @deleteTreeHole="deleteTreeHole"></process>
            </el-collapse-item>
          </el-collapse>

          <hr>
        </div>

        <!-- 文章内容 -->
        <div v-html="articleContentHtml" class="entry-content"></div>
        <el-image v-if="imgPreviewUrl" ref="previewImg" style="display: none" :src="imgPreviewUrl"
                  :preview-src-list="srcList">
        </el-image>
        <!-- 最后更新时间 -->
        <div class="article-update-time">
          <span>文章最后更新于 {{ article.updateTime }}</span>
        </div>
        <!-- 分类 -->
        <div class="article-sort">
          <span @click="$router.push({path: '/sort', query: {sortId: article.sortId, labelId: article.labelId}})">{{ article.sort.sortName +" ▶ "+ article.label.labelName}}</span>
        </div>
        <!-- 作者信息 -->
        <blockquote>
          <div>
            作者：{{article.username}}
          </div>
          <div>
            版权声明：转载请注明文章出处
          </div>
        </blockquote>
        <!-- 点赞 -->
        <div class="myCenter" id="article-like">
          <i class="el-icon-thumb article-like-icon" :class="{'article-like': article.likeCount}"></i>
        </div>

        <!-- 评论 -->
        <div v-if="article.commentStatus === true">
          <comment :type="'article'" :source="article.id" :userId="article.userId"></comment>
        </div>
      </div>

      <div id="toc" class="toc"></div>
    </div>

    <div style="background: var(--background)">
      <myFooter></myFooter>
    </div>

    <el-dialog title="最新进展"
               :visible.sync="weiYanDialogVisible"
               width="40%"
               :append-to-body="true"
               destroy-on-close
               center>
      <div>
        <div class="myCenter" style="margin-bottom: 20px">
          <el-date-picker
            v-model="newsTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            align="center"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
        <commentBox :disableGraffiti="true"
                    @submitComment="submitWeiYan">
        </commentBox>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import CeBianLan from "@/components/ceBianLan";
  const myFooter = () => import( "./common/myFooter");
  const comment = () => import( "./comment/comment");
  const process = () => import( "./common/process");
  const commentBox = () => import( "./comment/commentBox");
  import MarkdownIt from 'markdown-it';
  export default {
    components: {
      CeBianLan,
      myFooter,
      comment,
      commentBox,
      process
    },

    data() {
      return {
        imgPreviewUrl: '',
        srcList:"",
        // 目录
        toc:[],
        // 现在的url 用于页面当前链接
        nowURL: '',
        // 更新多少天前
        Day: 0,
        // 标题
        title: '',
        createTime: '',
        updateTime: '',
        // 浏览量
        viewCount:'',
        // 当前主题
        themeName: "joe",
        id: this.$route.query.id,
        article: {},
        articleContentHtml: "",
        treeHoleList: [],
        weiYanDialogVisible: false,
        newsTime: ""
      };
    },
    created() {
      this.getArticle();
      var _this = this
      window.previewImg = function (url) {
        _this.imgPreviewUrl = url
        setTimeout(() => {
          _this.$refs.previewImg.showViewer = true
          // 停止页面滚动
          const m = (e) => {
            e.preventDefault()
          };
          document.body.style.overflow = 'hidden';
          document.addEventListener("touchmove", m, false); // 禁止页面滑动

        }, 200)
      }
    },
    methods: {
      getTitle() {
        // 生成目录
        this.$nextTick(() => {
          // 根据之前设置的ref来获取文章的小标题
          const article_content = this.$refs.ff;//这里的ff改成你具体设置的
          // 想获取的标题，将想获取的小标题的标签添加到这
          const titleTag = ["H1", "H2", "H3"];
          //存放小标题的数组
          let titles = [];
          article_content.childNodes.forEach((e, index) => {
            //具体执行步骤，比如：打印看看
            // console.log(e, index);
            if (titleTag.includes(e.nodeName)) {
              //具体封装过程
              console.log(e.nodeName);
              const id = "header-" + index;
              // 设置元素id
              e.setAttribute("id", id);
              titles.push({
                id: id,
                title: e.innerHTML,
                level: Number(e.nodeName.substring(1, 2)),
                nodeName: e.nodeName,
                //read：后期主要用来判断当前阅读的是哪一个小标题的内容
                read:false
              });
            }
          });
          //再data中定义catalog
          this.catalog = titles;
        });
      },
      // 父组件 ，对这些数据进行保存
      refreshScroll(values){
        this.values = values
      },

      // 父组件
      handleScroll(){
        let  top = this.$refs.scroll.wrap.scrollTop
        for (let i = 0;i < this.values.length - 1;i ++) {
          // 判断当前页面的位置 应该属于哪个标签，我这里是标签下的内容没有结束，目录都是这个标签高亮
          if (top >= this.values[i].height - 1 && top < this.values[i + 1].height) {
            // -1 是我发现点击目录滑动的时候，会有<1 的误差，导致目录高亮不改变

            //  让之变成
            this.$refs.detail.changeItem(i)  // 通过这个方法，改变目录标签高亮
          }
        }
        // 特殊处理 第一个和最后一个
        if (top< this.values[0].height ) this.$refs.detail.changeItem(0)
        if (top >= this.values[this.values.length - 1].height - 1 ) this.$refs.detail.changeItem(this.values.length - 1)

      },

      deleteTreeHole(id) {
        if (this.$common.isEmpty(this.$store.state.currentUser)) {
          this.$message({
            message: "请先登录！",
            type: "error"
          });
          return;
        }

        this.$confirm('确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success',
          center: true
        }).then(() => {
          this.$http.get(this.$constant.baseURL + "/weiYan/deleteWeiYan", {id: id})
            .then((res) => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getNews();
            })
            .catch((error) => {
              this.$message({
                message: error.message,
                type: "error"
              });
            });
        }).catch(() => {
          this.$message({
            type: 'success',
            message: '已取消删除!'
          });
        });
      },
      submitWeiYan(content) {
        let weiYan = {
          content: content,
          createTime: this.newsTime,
          source: this.article.id
        };

        this.$http.post(this.$constant.baseURL + "/weiYan/saveNews", weiYan)
          .then((res) => {
            this.weiYanDialogVisible = false;
            this.newsTime = "";
            this.getNews();
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      getNews() {
        this.$http.post(this.$constant.baseURL + "/weiYan/listNews", {
          current: 1,
          size: 9999,
          source: this.article.id
        })
          .then((res) => {
            if (!this.$common.isEmpty(res.data)) {
              res.data.records.forEach(c => {
                c.content = c.content.replace(/\n{2,}/g, '<div style="height: 12px"></div>');
                c.content = c.content.replace(/\n/g, '<br/>');
                c.content = this.$common.faceReg(c.content);
                c.content = this.$common.pictureReg(c.content);
              });
              this.treeHoleList = res.data.records;
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      onScrollPage() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop < (window.innerHeight / 4)) {
          $(".toc").css("top", window.innerHeight / 2);
          $(".toc").css("display", "unset");
        } else if (scrollTop > (window.innerHeight / 4) && scrollTop < ($("#article-like").offset().top - window.innerHeight)) {
          $(".toc").css("top", "100px");
          $(".toc").css("display", "unset");
        } else {
          $(".toc").css("display", "none");
        }
      },
      getTocbot() {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.$constant.tocbot;
        document.getElementsByTagName('head')[0].appendChild(script);

        // 引入成功
        script.onload = function () {
          tocbot.init({
            tocSelector: '#toc',
            contentSelector: '.entry-content',
            headingSelector: 'h1, h2, h3, h4, h5, h6',
            scrollSmooth: true,
            fixedSidebarOffset: 'auto',
            scrollSmoothOffset: -100,
            hasInnerContainers: false
          });
        }
      },
      addId() {
        let headings = $(".entry-content").find("h1, h2, h3, h4, h5, h6");
        headings.attr('id', (i, id) => id || 'toc-' + i);
      },
      // 点赞方法
      like() {
        this.$http.get(this.$constant.baseURL + "/article/like", {id: this.id})
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: res.data.message,
                type: "success"
              });
              this.article.likeCount = this.article.likeCount + 1;
            } else {
              this.$message({
                message: res.data.message,
                type: "error"
              });
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      getArticle() {
        this.$http.get(this.$constant.baseURL + "/article/getArticleById", {id: this.id, flag: true})
          .then((res) => {
            debugger
            if (!this.$common.isEmpty(res.data)) {
              this.article = res.data;
              this.title = this.article.articleTitle;
              this.createTime = this.article.createTime
              this.updateTime = this.article.updateTime
              // 将this.updateTime的格式变成yyyy-MM-dd
              this.updateTime = this.updateTime.substring(0, 10);
              //写一个距离上一次更新已经过去了多少天
              const tempTime = this.$common.getDateDiff(this.article.updateTime)
              this.Day = this.createTime - tempTime
              // this.Day = this.$common.getDateDiff(this.$common.getDate())
              this.viewCount = this.article.viewCount
              this.nowURL = window.location.href;
              this.getNews();
              const md = new MarkdownIt({breaks: true});
              this.articleContentHtml = md.render(this.article.articleContent);
              // this.article.articleContent是markdown格式的,需要从里面找到所有的一级标题，然后把一级标题存到新的数组里面
              let reg = /<h1.*?>(.*?)<\/h1>/g;
              let arr = this.articleContentHtml.match(reg);
              if (arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                  arr[i] = arr[i].replace(/<[^>]+>/g, "");
                }
                this.getTitle()

                this.$nextTick(() => {
                  this.$refs.CeBianLan.init(null, null, this.catalog);
                });
              }
              let _this = this
              this.srcList = []
              this.articleContentHtml.replace(/<img src="[^>]*>/gi, function (val) {
                const srcRegex = /src="([^"]*)"/;
                let match = val.match(srcRegex);
                if (match) {
                  var src = match[1]
                  var url = '<img onclick="previewImg(\'' + src + '\')" src="' + src + '">'
                  _this.srcList.push(src)
                  _this.articleContentHtml = _this.articleContentHtml.replace(val, url);
                }
              })

              this.$nextTick(() => {
                // this.$refs.CeBianLan.init(null,null,this.catalog);
                this.highlight();
                this.addId();
                // todo 只有程序相关文章才显示toc
                // this.getTocbot();
              });
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      highlight() {
        let attributes = {
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          spellcheck: "false",
          contenteditable: "false"
        };

        $("pre").each(function (i, item) {
          let preCode = $(item).children("code");
          let classNameStr = preCode[0].className;
          let classNameArr = classNameStr.split(" ");

          let lang = "";
          classNameArr.some(function (className) {
            if (className.indexOf("language-") > -1) {
              lang = className.substring(className.indexOf("-") + 1, className.length);
              return true;
            }
          });

          // 检测语言是否存在，不存在则自动检测
          let language = hljs.getLanguage(lang.toLowerCase());
          if (language === undefined) {
            // 启用自动检测
            let autoLanguage = hljs.highlightAuto(preCode.text());
            preCode.removeClass("language-" + lang);
            lang = autoLanguage.language;
            if (lang === undefined) {
              lang = "java";
            }
            preCode.addClass("language-" + lang);
          } else {
            lang = language.name;
          }

          $(item).addClass("highlight-wrap");
          $(item).attr(attributes);
          preCode.attr("data-rel", lang.toUpperCase()).addClass(lang.toLowerCase());
          // 启用代码高亮
          hljs.highlightBlock(preCode[0]);
          // 启用代码行号
          hljs.lineNumbersBlock(preCode[0]);
        });

        $("pre code").each(function (i, block) {
          $(block).attr({
            id: "hljs-" + i,
          });

          $(block).after(
            '<a class="copy-code" href="javascript:" data-clipboard-target="#hljs-' +
            i +
            '"><i class="fa fa-clipboard" aria-hidden="true"></i></a>'
          );
          new ClipboardJS(".copy-code");
        });

        if ($(".entry-content").children("table").length > 0) {
          $(".entry-content")
            .children("table")
            .wrap("<div class='table-wrapper'></div>");
        }
      }
    }
  }
</script>

<style scoped>

  .article-head {
    height: 40vh;
    position: relative;
  }

  .article-image::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--miniMask);
    content: "";
  }

  .article-info-container {
    position: absolute;
    bottom: 15px;
    left: 20%;
    color: var(--white);
  }

  .article-info-news {
    position: absolute;
    bottom: 10px;
    right: 20%;
    cursor: pointer;
    animation: scale 1s ease-in-out infinite;
  }

  .article-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .article-info {
    font-size: 14px;
    user-select: none;
  }

  .article-info i {
    margin-right: 6px;
  }

  .article-info span:not(:last-child) {
    margin-right: 5px;
  }

  .article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  .article-update-time {
    color: var(--greyFont);
    font-size: 12px;
    margin: 20px 0;
    user-select: none;
  }

  blockquote {
    line-height: 2;
    border-left: 0.2rem solid var(--blue);
    padding: 10px 1rem;
    background-color: var(--azure);
    border-radius: 4px;
    margin: 0 0 40px 0;
    user-select: none;
    color: var(--black);
  }

  .article-sort {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .article-sort span {
    padding: 3px 10px;
    background-color: var(--themeBackground);
    border-radius: 5px;
    font-size: 14px;
    color: var(--white);
    transition: all 0.3s;
    margin-right: 25px;
    cursor: pointer;
    user-select: none;
  }

  .article-sort span:hover {
    background-color: var(--red);
  }

  .article-like {
    color: var(--red) !important;
  }

  .article-like-icon {
    font-size: 60px;
    cursor: pointer;
    color: var(--greyFont);
    transition: all 0.5s;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .article-like-icon:hover {
    transform: rotate(360deg);
  }

  .process-wrap {
    margin: 0 0 40px;
  }

  .process-wrap hr {
    position: relative;
    margin: 10px auto 60px;
    border: 2px dashed var(--lightGreen);
    overflow: visible;
  }

  .process-wrap hr:before {
    position: absolute;
    top: -14px;
    left: 5%;
    color: var(--lightGreen);
    content: '❄';
    font-size: 30px;
    line-height: 1;
    transition: all 1s ease-in-out;
  }

  .process-wrap hr:hover:before {
    left: calc(95% - 20px);
  }

  .process-wrap >>> .el-collapse-item__header {
    border-bottom: unset;
    font-size: 20px;
    background-color: var(--background);
    color: var(--lightGreen);
  }

  .process-wrap >>> .el-collapse-item__wrap {
    background-color: var(--background);
  }

  .process-wrap .el-collapse {
    border-top: unset;
    border-bottom: unset;
  }

  .process-wrap >>> .el-collapse-item__wrap {
    border-bottom: unset;
  }

  @media screen and (max-width: 700px) {
    .article-info-container {
      left: 20px;
      max-width: 320px;
    }

    .article-info-news {
      right: 20px;
    }
  }
  .entry-content p {
    color: var(--articleFontColor);
    line-height: 35px;
    word-break: break-word;
    font-size: 18px;
  }
</style>
